import React from 'react'
import * as styles from './faq.module.css'

const CollapsedIcon = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
      {...props}
    >
      <path
        d="M15.772 18.388c0-2.5 1.969-2.918 2.647-3.595a3.749 3.749 0 10-6.185-3.892"
        stroke="#EF4B40"
        strokeWidth={3.327}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.773 23.388a1.25 1.25 0 10-.001-2.499 1.25 1.25 0 000 2.5z"
        fill="#EF4B40"
        stroke="#EF4B40"
        strokeWidth={3.327}
      />
      <path
        clipRule="evenodd"
        d="M15.772 28.388c6.899 0 12.491-5.596 12.491-12.5 0-6.903-5.592-12.5-12.49-12.5-6.9 0-12.492 5.597-12.492 12.5 0 6.904 5.593 12.5 12.491 12.5z"
        stroke="#EF4B40"
        strokeWidth={3.327}
      />
    </svg>
  )
}

const ExpandedIcon = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
      {...props}
    >
      <path
        d="M21.25 15H8.75"
        stroke="#EF4B40"
        strokeWidth={3}
        strokeLinecap="round"
      />
      <path
        clipRule="evenodd"
        d="M15 27.5c6.904 0 12.5-5.597 12.5-12.5 0-6.904-5.596-12.5-12.5-12.5S2.5 8.096 2.5 15c0 6.903 5.596 12.5 12.5 12.5z"
        stroke="#EF4B40"
        strokeWidth={3}
      />
    </svg>
  )
}

export { CollapsedIcon, ExpandedIcon }
