import React from "react"
import { StandardLayout } from "../layout/layout"
import Faq from "../components/faq"
import * as styles from './donate.module.css'
import { MediumParagraph } from '../typography'
import * as colors from '../colors.module.css'
import {StaticImage} from 'gatsby-plugin-image'

const Donate = (props) => {
  return (
    <StandardLayout
      title="Donate"
      scripts={<script src="https://donorbox.org/widget.js" paypalExpress="true" />}
      subtitle="to leverage the power of sports and African pride in developing young leaders"
      backgroundColor={colors.goldenBackground}
      titleColor={colors.red}
      img={<StaticImage src="../images/leader.jpeg" className={styles.donateImage} loading="eager" />}
      ogImageSrc="../images/leader.jpeg"
      excludeDonateAndSignup
    >
      <div className={styles.donorboxContainerContainer}>
        <div className={styles.donorboxContainer}>
          <div className={styles.donorboxIframeContainer}>
            <iframe src="https://donorbox.org/embed/dsa-organizational-support?hide_donation_meter=true&a=b"
              name="donorbox"
              allowpaymentrequest=""
              seamless="seamless"
              frameborder="0"
              scrolling="no"
              height="900px"
              width="100%"
              style={{
                maxWidth: '500px',
                minWidth: '250px',
                maxHeight: 'none !important'
              }}/>
          </div>
          <div className={styles.donorboxExplanation}>
            <MediumParagraph>Thank you for your support! With your help we can empower thousands of youth in Nigeria and other African countries with opportunities to participate in physical activity through sports and exercise.</MediumParagraph>
            <MediumParagraph>Supporting our impactful programs will positively impact their wellbeing and help them become leaders in their communities.</MediumParagraph>

              {/* described below is very important but if you are undecided about which specific program to give to and would like Dream Sports Africa to help you make this decision then you can donate here. 100% of our funding goes directly to youth focused activities and local staff in African who manage our programs.</p> */}
            {/* <p>TODO: Add more text!</p> */}
          </div>
        </div>
      </div>
      <Faq />
    </StandardLayout>
  )
}

export default Donate
