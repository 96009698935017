import React, { useState } from "react";
import * as styles from "./faq.module.css";
import { CollapsedIcon, ExpandedIcon } from "./faqIcons";

const AccordionItem = ({ question, answer }) => {
  const [hideAnswer, setHideAnswer] = useState(true);
  const toggleAnswer = () => setHideAnswer(!hideAnswer);

  return (
    <div className={styles.faq}>
      {hideAnswer ? (
        <CollapsedIcon onClick={toggleAnswer} />
      ) : (
        <ExpandedIcon onClick={toggleAnswer} />
      )}
      <div className={styles.question} onClick={toggleAnswer}>
        <h3>{question}</h3>
      </div>
      <div className={`${styles.answer} ${hideAnswer ? styles.hide : ""}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

const Faq = () => {
  const data = [
    {
      question:
        "I have a question, comment, or concern about my donation. Who can I contact?",
      answer:
        "Please feel free to send an email to info@dsa.org or call +1 (734) 730-7378 with any questions, comments, or concerns.",
    },
    {
      question: "Is my donation tax-deductible?",
      answer:
        "Yes. Dream Sports Africa is a 501(c)(3) nonprofit organization, so donations in any amount are tax-deductible.",
    },
    {
      question: "Will I get a receipt?",
      answer:
        "Yes. You will receive a receipt via email immediately following your online donation, and we will also email you a signed donation acknowledgement letter for your records.",
    },
    {
      question: "Is my online donation secure?",
      answer:
        "Yes. We use industry-standard SSL (secure socket layer) technology to protect your information and provide a secure environment for online donations.",
    },
    {
      question: "What are the benefits of becoming a monthly donor?",
      answer:
        "We’re so glad you asked! Our monthly donors are proud champions of African youth and believers in the power of sports to inspire. By providing a reliable source of funding to sustain our work, you enable us to dedicate more of our time and resources to providing educational programming that transforms the lives of young Africans.",
    },
  ];

  return (
    <div className={styles.faqSection}>
      <h1>FREQUENTLY ASKED QUESTIONS</h1>
      <div>
        {data.map(({ question, answer }, index) => (
          <AccordionItem key={index} question={question} answer={answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
